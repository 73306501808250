._formField_uazmk_1200 label[for="file"],
._formField_uazmk_1200 input[type="file"],
._formField_uazmk_1200 label[for="src"] {
  display: none;
}

._formField_uazmk_1200:first-of-type:before {
  content: "Add an image from a URL:";
  font-size: 12px;
}

._popupContainer_uazmk_1220 {
  z-index: 10;
}

._toolbarToggleSingleGroup_uazmk_1220:nth-child(1) button._toolbarToggleItem_uazmk_1220:nth-child(3) {
  display: none;
}

._dialogContent_uazmk_602 {
  flex-direction: column;
}

._linkDialogPopoverContent_uazmk_600 ._formField_uazmk_1200:first-of-type:before {
  content: " "
}

._contentEditable_uazmk_379 {
  min-height: 14rem;
}