@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: "Manrope", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 500;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@layer components {
  .link {
    @apply text-primary font-bold cursor-pointer;
  }

  .form-y {
    @apply py-4 lg:py-10;
  }

  .form-x {
    @apply px-4 lg:px-10;
  }

  .form-xy {
    @apply form-x form-y;
  }

  .form-center {
    @apply mb-10 mx-6 md:mx-12 lg:mx-12 xl:mx-20 2xl:mx-40;
  }

  .mobile-full-screen {
    @apply fixed md:relative inset-0 overflow-y-auto md:overflow-y-visible z-20;
  }

  .mobile-icon {
    @apply w-[18px] h-[18px];
  }

  .mobile-link {
    @apply text-sm flex items-center space-x-1 link w-24;
  }

  .mobile-kebab {
    @apply w-[24px] h-[24px] border rounded-md border-grey-30;
  }
}

@layer utilities {

  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }
}